/* Parent element that contains puzzle game */
.puzelm {
position:relative;
margin:1px auto;
padding:1px;
text-align:center;
}

/* Canvas with puzzle */
.puzcnv {
display:block;
margin:2px auto 29px auto;
}

/* Mini-image that shows original image */
.puzimg {
position:absolute;
left:15px;
right:0;
left:0;
bottom:0;
margin:0 auto 0 8%;
width:30px;
height:25px;
cursor:pointer;
}
.puzimg:hover {
width:39%;
height:auto;
border:2px solid blue;
background:#fff;
padding:2px;
}

/* Button that auto-solves the puzzle */
.puzsolve {
display:block;
margin:-25px auto 1px auto;
}

.button-for-letters {
    border: none;
    background-color: #156F13;
  }

.button-for-disabled-letters {
    border-radius: 20px;
    opacity: .40;
}
  
.house_build_img {
    width: 80px;
    height: 40px;
}

.very_very_small_img {
    width: 45px;
    height: 45px;
}
  
.very_very_small_img2 {
  width: 45px;
  height: 25px;
}

.picture-has-been-clicked {
      opacity: .50;
 }

.use-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.font-size-very-large {
  font-size: 40px;
 }

.set-width-100 {
  width: 100%
}

.rectangleTutor {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #156F13;
}

.rectangleBuildHome {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #BEE7F1;
}

.rectangleSoupKitchen {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #22B14C;
}


.skip-space-wordList{
    height: 100px;
}

.skip-space-underScore{
  height: 10px;
}

.comedy_club_background {
  background-image: url('./gameBoardAssets/bg_comedyClub.png');
  min-height: 30em;
  min-width: 100%;
} 

.comic-position-img{
  height: 18em;
  width: 7em;
  position: absolute;
  top: 75%;
  left: 45%;
  transform: translate(-45%, -75%);
}

/* (A) SPEECH BOX */
.speech-comedy-club-activity {
  /* (A1) FONT */
  font-family: arial, sans-serif;
  font-size: .9em;
 
  /* (A2) COLORS */
  color: #000000;
  background: #b2b2b2;
 
  /* (A3) DIMENSIONS + POSITION */
  
  position: absolute;
  top: 65%;
  left: 2%;
  transform: translate(-2%, -65%);
  padding: 15px;
  border-radius: 10px;
  max-width: 150px;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-comedy-club-activity::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
 
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

  /* (C3) RIGHT */
.right.speech-comedy-club-activity::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #b2b2b2;
  border-right: 0;
 
  /* (C3-2) POSITION AT RIGHT */
  right: -20px; top: 30%;
  margin-top: -20px;
}

.friend-puzzle-position-img{
  height: 13em;
  width: 4em;
  position: absolute;
  top: 100%;
  left: 98%;
  transform: translate(-98%, -100%);
  z-index: 5;
}

/* (A) SPEECH BOX */
.speech-puzzle-activity {
  /* (A1) FONT */
  font-family: arial, sans-serif;
  font-size: .9em;
 
  /* (A2) COLORS */
  color: #fff;
  background: #001C54;
 
  /* (A3) DIMENSIONS + POSITION */
  
  position: absolute;
  top: 80%;
  left: 80%;
  transform: translate(-80%, -80%);
  padding: 15px;
  border-radius: 10px;
  max-width: 200px;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-puzzle-activity::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
 
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

  /* (C3) RIGHT */
.right.speech-puzzle-activity::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #001C54;
  border-right: 0;
 
  /* (C3-2) POSITION AT RIGHT */
  right: -20px; top: 30%;
  margin-top: -20px;
}

.under-cover-puzzle-background {
  height: 480px;
  width: 1200px;
  position: absolute;
  top: 40px;
}

.under-cover-img{
   height: 500px;
   width: 660px;
   background-size: cover;
   border: 4px solid black;
}

/* 6 columns & 5 rows */
.cover-up-square{
  height: 110px;
  width: 110px;
}

.under-cover-puzzle-background-medium {
  height: 340px;
  width: 480px;
  position: absolute;
  top: 48px;
}

.under-cover-img-medium{
  height: 340px;
  width: 480px;
  background-size: cover;
  border: 4px solid black;
}

.under-cover-puzzle-background-narrow {
  height: 300px;
  width: 550px;
  position: absolute;
  top: 40px;
}

/* 6 columns and 5 rows */
.cover-up-square-medium{
  height: 68px;
  width: 80px;
}

.under-cover-img-narrow{
  height: 250px;
  width: 360px;
  background-size: cover;
  border: 4px solid black;
}

/* 6 columns and 5 rows */
.cover-up-square-narrow{
   height: 50px;
   width: 60px;
}

.under-cover-text-position{
    position: absolute;
    top: 40px;
    left: 680px;
    width: 25%;
    text-align: left;
}

.under-cover-text-position-medium{
  position: absolute;
  top: 20px;
  left: 490px;
  width: 37%;
  text-align: left;
}

.under-cover-text-position-narrow{
  position: absolute;
  top: 40px;
  left: 380px;
  text-align: left;
}

.under-cover-friend-position{
  height: 14em;
  width: 4.25em;
  position: relative;
  top: -30%;
  left: 40%;
  transform: translate(-40%, 30%);
}

.under-cover-friend-position-medium{
  height: 13em;
  width: 3.5em;
  position: relative;
  top: -15%;
  left: 65%;
  transform: translate(-65%, 15%);
}

.under-cover-friend-position-narrow{
  height: 13em;
  width: 3.5em;
  position: relative;
  top: -8%;
  left: 100%;
  transform: translate(-100%, 8%);
}

/* (A) SPEECH BOX */
.speech-vacation-activity {
  /* (A1) FONT */
  font-family: arial, sans-serif;
  font-size: .9em;
 
  /* (A2) COLORS */
  color: #fff;
  background: #001C54;
 
  /* (A3) DIMENSIONS + POSITION */
  position: absolute;
  top: 90%;
  left: 0%;
  transform: translate(0%, -90%);
  padding: 15px;
  border-radius: 10px;
  max-width: 140px;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-vacation-activity::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
 
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

  /* (C3) RIGHT */
.right.speech-vacation-activity::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #001C54;
  border-right: 0;
 
  /* (C3-2) POSITION AT RIGHT */
  right: -20px; top: 30%;
  margin-top: -20px;
}


.friend-puzzle-positionWordPuzzle-img{
  height: 14em;
  width: 4.25em;
  position: absolute;
  top: 95%;
  left: 100%;
  transform: translate(-100%, -95%);
}

/* (A) SPEECH BOX */
.speech-wordPuzzle-activity {
  /* (A1) FONT */
  font-family: arial, sans-serif;
  font-size: .9em;
 
  /* (A2) COLORS */
  color: #fff;
  background: #001C54;
 
  /* (A3) DIMENSIONS + POSITION */
  
  position: absolute;
  top: 72%;
  left: 87%;  transform: translate(-87%, -72%);
  padding: 15px;
  border-radius: 10px;
  min-width: 140px;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-wordPuzzle-activity::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
 
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

  /* (C3) RIGHT */
.right.speech-wordPuzzle-activity::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #001C54;
  border-right: 0;
 
  /* (C3-2) POSITION AT RIGHT */
  right: -20px; top: 30%;
  margin-top: -20px;
}

.puzzle-completed-picture-position{
   position: absolute;
   right: 5%;
   top: 35%;
   z-index: 5
}

.completed-picture-size{
    width: 20%;
    height: 20%;
}

.arrowKeys-maze-position{
  height: 3.5em;
  width: 3.5em;
  position: absolute;
  top: 52%;
  left: 140%;
  transform: translate(-140%, -52%);
  border:2px solid black;
  border-radius: 10px;
  z-index: 5;
}