.left-side-img
{
    height:6em;
    width: 40%;
}

.left-side-img-car
{
    height:5em;
    width: 8em;
}

.text-for-left-side-img{
  width: 60%;
}

.left-side-img2
{
    height:5em;
    width: 70%;
}

.left-side-img-small
{
    height: 3.5em;
    width: 4em;
}

.left-side-img-car-small
{
    height: 3.3em;
    width: 5em;
}

.medium-size-img
{
    height:10em;
    width: 8em;
 }
 
.small-size-img
{
    height:8em;
    width: 6em;
 }
 
.very-small-size-img
{
    height:6em;
    width: 4em;
 }

.picture-container{
   position: relative;
}

.picture-border
{
  border-width: 4px;
  border-color: black;
  border-radius: 8px;
  border: solid;
}

.picture-text-bottom
{
  position: absolute;
  top: 95px;
  left: 40px;
  background-color: white;
  color: black;
  z-index: 1;
}

.button-placement-bottom
{
  position: absolute;
  top: 120px;
  left: 30px;
  background-color: white;
  color: black;
  z-index: 1;
}

.button-placement-bottom2
{
  position: absolute;
  top: 120px;
  left: 12px;
  background-color: white;
  color: black;
  z-index: 1;
}

.disabled-picture {
  opacity: .50;
}

.funstuff-item-picture
{
  
  height: 200px;
  width: 150px;
}

.funstuff-item-text-bottom
{
  color: black;
 z-index: 1;
}

.job-picture
{
  
  height: 125px;
  width: 100px;
}


.table-wide
{
  width: 100%
}

.game-board
{
    top: 0%;
    background-position: 0;
    background-size: cover;
    min-height: 88%;
}

.game-board-medium
{
    top: 0%;
    background-position: 0;
    background-size: cover;
    min-height: 99%;
}

.game-board-narrow
{
    top: 0%;
    background-position: 0;
    background-size: cover;
    min-height: 102%;
}

.game-board-title-background
{
    background-color: #DDDDDD;
    position:absolute;
    left: 30%;
    min-height: 18px;
    max-width: 280px;
    border-radius: 8px;
    border-width: 2px;
    border-color: #b2b2b2;
}

.background-fit
{
    background-size: 100%;
}

.icon-text{
  position: absolute;
  top: 22px;
  left: 23px;
  z-index: 2;
  font-weight: bold;
  font-size: 0.8rem;
}

.game-view-icons
{
    position: absolute!important;
    left: 17px;
    top: 25px;
}

.game-view-info
{
    position: absolute!important;
    right: 20px;
    top: 25px;
    background-color: #DDDDDD;
    border-radius: 2px;
    border-width: 1px!important;
    border-color: black!important;
}

.facedown-game-card
{
    height: 140px!important;
    width: 100px!important;
    border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    position: absolute;
    cursor: pointer;
}

.game-card-blue
{
    background-color: #7490C3;
    border-color: #203864!important;
}

.game-card-green
{
    background-color: #2FFF97;
    border-color: #00BC5E!important;
}

.faceup-game-card
{
    height: 280px!important;
    width: 200px!important;
    border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    position: absolute;
    top: 40%;
    z-index: 5;
}

.faceup-game-card-medium
{
    height: 250px!important;
    width: 170px!important;
    border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    position: absolute;
    top: 40%;
  }

.futureModalBackgroundImage{
    background-image: url('./gameViewAssets/bg_future.png');
    width: 100%;
}

.futureModalBackgroundImage2{
  background-color: black;
}
 
 .futureModalHdrFooterBackground {
    background-color: rgba(81, 59, 247, .6);
 }
 
.myTable-row-highlight {
    background-color: #ACE7FA!important;
}

.leaderboardBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 0px;
    margin: 0px;
    z-index: 0;
    opacity: 40%;
}

.leaderboardBackground2 {
    background-image: url('./gameViewAssets/bg_leader.jpg');
    z-index: 0!important;
    background-size: cover;
    size: 100%;
}

.leaderboardContent {
    z-index: 10!important;
    font-weight: bold;
    width: 100%;
    color: rgba(0,0,0,1.00)!important;
}
  
  .friend-game-img
  {
  height: 75%;
  width: 50%;
  }   
   
  .horse-game-img
  {
  height: 100%;
  width: 100%;
  }   

  .holiday-image-class
  {
    height: 150%;
    width: 90%;
  }

  .holiday-position
  {
      position: absolute;
      top: 30%;
      left: 3%;
  }

  .friend-position
  {
      position: absolute;
      top: 85%;
      left: 43%;
      transform: translate(-43%, -85%);
  }
  
  .friend-position-narrow
  {
      position: absolute;
      top: 80%;
      left: 54%;
      transform: translate(-54%, -80%);
  }
  
  .funStuff-position
  {
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translate(-50%, -90%);
  }
  
  .pet-position
  {
      position: absolute;
      top: 100%;
      left: 66%;
      transform: translate(-66%, -100%);
  }
  
  .pet-game-img-wide
  {
    height: 100px;
    width: 180px;
  }   
  
  .pet-game-img-medium
  {
    height: 80px;
    width: 160px;
  }   
  
  .pet-game-img-narrow
  {
    height: 60px;
    width: 140px;
  }   
  
  .pet-game-img-cat-wide
  {
    height: 85px;
    width: 170px;
  }   
  
  .pet-game-img-cat-medium
  {
    height: 75px;
    width: 150px;
  }  
    
  .pet-game-img-cat-narrow
  {
    height: 65px;
    width: 130px;
  }   

  .funStuff-name-style
  {
    background-color: white;
    font-size: 16px;
    color: blue;
    min-height: 28px;
    min-width: 65px;
    border-radius: 20%;
    border-style: solid;
    border-color: #6F6F6F!important;
    border-width: 1px!important;
  }
  
  .funStuff-name-position
  {
      position: absolute;
      top: 58%;
      left: 59%;
  }

  
  .funStuff-name-position-cat
  {
      position: absolute;
      top: 38%;
      left: 59%;
  }

  .other-image-size
  {
     height: 175px;
     width: 175px;
  }
  
  .other-image-position
  {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -90%);
  }
  
  .other-image-position-narrow
  {
      position: absolute;
      top: 90%;
      left: 70%;
      transform: translate(-70%, -90%);
  }
  
  .friend-name-position
  {
     position: absolute;
     top: 25%;
     left: 48%;
  }

  .friend-name-position-medium
  {
      position: absolute;
      top: 26%;
      left: 48%
  }

  .friend-name-position-narrow
  {
      position: absolute;
      top: 23%;
      left: 48%;
  }

  .friend-name-container
  {
      transform: translate(-48%, -30%);
      background-color: #6F6F6F;
      color: white;
      min-height: 32px;
      min-width: 85px;
      border-radius: 45%;
      border-style: solid;
      border-color: #6F6F6F!important;
      border-width: 1px!important;

  }
  .friend-dialog-position
  {
      position: absolute;
      top: 85%;
      left: 48%;
      transform: translate(-48%, -80%);
  }

  .display-on-top
  {
      z-index: 9;
  }

  .gameInfoModal
  {
     border-color: black;
     border-width: 4px!important;
     background-color: #B6ECF5;
     font-family: "Comic Sans MS", cursive, sans-serif;
  }

  .gameInfoModalBackground
  {
    background-color: #B6ECF5;
  }

  .gameWarningModal
  {
     border-color: black;
     border-width: 4px!important;
     background-color: #FFFF99;
     font-family: "Comic Sans MS", cursive, sans-serif;
  }

  .gameWarningModalBackground
  {
    background-color: #FFFF99;
  }
  
 .goodNewsModal
  {
     border-color: black;
     border-width: 4px!important;
     background-color: #97FFBA;
     font-family: "Comic Sans MS", cursive, sans-serif;
  }

  .goodNewsModalBackground
  {
    background-color: #97FFBA;
  }
  
.small_sign_thumbnail_img
    {
    height: 2em;
    width: 2em;
 }

 .information-sign-position
 {  position: absolute;
    top: 37px;
    left: 20px;
 }
 
 .warning-sign-position
 {
     position: absolute;
     top: 37px;
     left: 60px;
     z-index: 2;
 }
 
 .warning-sign-position2
 {
      position: absolute;
      top: 37px;
      left: 100px;
 }
 
 .warning-sign-position3
 {
      position: absolute;
      top: 37px;
      left: 140px;
 } 
 
 .good-news-funStuff-position
 {
   position: absolute;
   top: 70px;
   left: 20px;
 }
     
  .good-news-savings-position
  {
      position: absolute;
      top: 70px;
      left: 60px;
  }

  .good-news-creditCard-position
  {
      position: absolute;
      top: 70px;
      left: 100px;
  }
  
  .good-news-timeWizard-position
  {
      position: absolute;
      top: 70px;
      left: 140px;
  }
  
  .good-news-college-position
  {
    position: absolute;
    top: 110px;
    left: 20px;
  }

  .good-news-house-position
  {
      position: absolute;
      top: 110px;
      left: 60px;
  }
  
  .good-news-takeTest-position
  {
      position: absolute;
      top: 110px;
      left: 100px;
  }

 .train-video-background
 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
 }
 
 .gameChoiceModalHdrFtrBackground {
    background-color: #B1D7F1;
 }

 .train_icon_endgame_img
{
    height: 3.75em;
    width: 9em;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 75%);
 }
 
.btn-small-dialog-response {
  padding: 0.15rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
 
 /* (A) Response BOX */
.response {
  /* (A1) FONT */
  font-family: arial, sans-serif;
 /* font-size: 1.0em;
 
  /* (A2) COLORS */
  color: black;
  background: #7490C3;
 
  /* (A3) DIMENSIONS + POSITION */
  
  padding: 15px;
  border-radius: 10px;
  border-color: #203864!important;
  border-width: 3px!important;
  border-style: solid;
  max-width: 35%;
}

.response-initial-position{
    position: absolute;
    top: 90%;
    left: 5%;
    transform: translate(-5%, -85%);
}

 .response-result-position{
    position: absolute;
    top: 70%;
    left: 5%;
    transform: translate(-5%, -85%);
 }

.button_response_color {
  background-color: #6F6F6F;
}


.speechEditButton {
  position: absolute;
  top: 25%;
  left: -2%;
  transform: translate(-40%, -1%);
}

.responseEditButton {
  position: absolute;
  top: 80%;
  left: 1%;
  transform: translate(-80%, -1%);
}

 /* (A) SPEECH BOX */
.speech {
    /* (A1) FONT */
    font-family: arial, sans-serif;
      
    /* (A2) COLORS */
    color: #fff;
    background: #262696;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 30%;
    left: 5%;
    transform: translate(-5%, -30%);
  
    padding: 10px;
    border-radius: 10px;
    max-width: 35%;
  }


  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
 
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

/* (B2) BOTTOM "CALLOUT TAIL" */
.bottom.speech::after {
  /* (B2-1) DOWN TRIANGLE */
  border-top-color: #262696;
  border-bottom: 0;
 
  /* (B2-2) POSITION AT BOTTOM */
  bottom: -20px; left: 70%;
  margin-left: -20px;
}

/* (C3) RIGHT */
.right.speech::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #262696;
  border-right: 0;
 
  /* (C3-2) POSITION AT RIGHT */
  right: -20px; top: 70%;
  margin-top: -20px;
}

.mood_retirement_narrow_size {
  height: 100px;
  max-width: 220px;
}

.mood_retirement_wide_size {
  height: 180px;
  max-width: 350px;
}

.mood_retirement_container {  
  background-image: url(./gameViewAssets/moodRetirementStatusDetail.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 }

.arrow_position_wide{
  height: 100px;
  width: 15px;   
  position: relative;
  top: 55px;
  left: 170px;
  transform-origin: 0% 100%;
  transform: translate(-170px, -55px);
}

.arrow_position_narrow{
  height: 70px;
  width: 8px;   
  position: relative;
  top: 20px;
  left: 110px;
  transform-origin: 0% 100%;
  transform: translate(-170px, -55px);
}

.smiley-face-position
{
    position: absolute;
    top: 85%;
    left: 5%;
    transform: translate(-5%, -85%);
}

.smiley-game-img
{
height: 22%;
width: 20%;
}   

.done-button-placement
{
    position: absolute;
    top: 470px;
    left: 275px;
}

.light-blue-color
{
    color: #2994FF;
}

.horizontal-timeline
{
    width: 90%;
    height: 4px;
    border: 1px solid brown;
    margin: 3px;
    display:inline-block;
    position: absolute;
    top: -6%;
    left: 5%;
}
  
.timelineVerticalLine{
  display: inline-block;
  height: 15px;
  width: 4px;
  top: -7%;
  margin-right: auto;
  position: absolute;
  background: brown;
}

.timelineNumberPlacement{
  top: -4.5%;
  margin-right: auto;
  position: absolute;
 }
  
.timelineFill {
  display: inline-block;
  height: 20px;
  background: rgba(0, 112, 192);
  position: absolute;
  left: 28%;
  top:  2%;
}

.timeline-train-position{
  position: absolute;
  top: -12%;
}

.timeline-train-img
{
    height: 30px;
    width: 70px;
}  

.timeline-end-game-text{
    position: absolute;
    top: -8%;
    left: 98%;
    width: 10%;
    font-weight: bold;
}

.timeline-bgn-game-text{
    position: absolute;
    top: -9%;
    left: -5%;
    width: 10%;
    transform: translate(-5%, -9%); 
    font-weight: bold;
}

.spinTrainAround{
  animation: rotateTrain 1s 3 linear;
}

@keyframes rotateTrain {
  100% {transform: rotate(359deg)};
}

.train-text-center {
    position: absolute;
    top: -12%;
    left: 40%;
}

.train-text-left {
  position: absolute;
  top: -12%;
  left: 15%;
}

.game-board-rectangle{
  height: 500px;
  
}

.modal-border{
  border-right: 5px solid #ADD1FF;
  border-left: 5px solid #ADD1FF;
}

.begin-month-text{
  position: absolute;
  top: -1%;
  left: 5%;
}

.end-month-text{
  position: absolute;
  top: -1%;
  left: 95%;
}

.click-image-icon{
   width: 10%;
   height: 10%;
   z-index: 5;
 }
   
.train-end-of-turn{
   width: 120%;
   height: 70%;
}

@keyframes moveLeft {
    0%   {left:0%; top:45%;}
    100% {left:83%; top: 45%;}
}

.money-train-info {
    position: absolute;
    top: 45%;
    left: 0%;
    height: 40%;
    width: 40%;
    z-index: 5;
}

.move-train-on-track{
    animation: moveLeft 2.5s 1 linear;
    animation-delay: .5s;
}

.monthly-status-chat-icon{
    height: 15%;
    width: 15%;
    position: absolute;
    top: 85%;
    left: 5%;
    z-index: 5;
}

.comic-sans-font{
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.train-next-turn-background{
  background-image: url('./gameViewAssets/TrainMovingBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.purchases-picture-position{
  position: absolute!important;
  top: 25px;
  right: 170px;
  cursor: pointer;
}

.purchases-picture-size{
  height: 15%;
  width: 15%;
}

.purchase-image-size{
   height: 100px;
   width: 80px;
}

.purchases-background {
  background-color:  #FDEDCF;
 }

.position-absolute{
  position: absolute!important;
}