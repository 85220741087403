.wheelContainer{
  width:200px;
  height:200px;
  background-image: url('./gameViewAssets/spinnerWheel.png');
  background-size: cover;
  border-radius:50%;
  border:5px solid #dde;
  position: absolute;
  left: 70%;
  top: 25%;
  overflow: hidden;
  z-index: 1;
}

.wheelContainer .one{
   position: absolute;
   left:65%;
   top: 5%;
   transform: rotate(20deg);
}
.wheelContainer .two{
   position: absolute;
   left: 85%;
   top: 30%;
   transform: rotate(45deg);
}
.wheelContainer .three{
   position: absolute;
   left: 85%;
   top: 55%;
   transform: rotate(90deg);
}
.wheelContainer .four{
  position: absolute;
  left: 65%;
  top: 80%;
  transform: rotate(135deg);
}
.wheelContainer .five{
  position: absolute;
  left: 30%;
  top: 80%;
  transform: rotate(190deg);
}
.wheelContainer .six{
  position: absolute;
  left: 10%;
  top: 58%;
  transform: rotate(230deg);
}
.wheelContainer .seven{
  position: absolute;
  left: 12%;
  top: 30%;
  transform: rotate(270deg);
}
.wheelContainer .eight{
  position: absolute;
  left: 32%;
  top: 7%;
  transform: rotate(330deg);
}

.spinArrowAround{
  animation: rotateArrow 1s 2 linear;
}

.spinnerArrow{
  position: absolute;
  top:5%;
  left:45%;
  transform: translate(-5%,-45%);
  height: 90px;
  width: 20px;   
  transform-origin: 45% 100%;
  z-index: 5;
}

@keyframes rotateArrow {
    100% {transform: rotate(359deg)};
 }

.spinnerCircle {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: black;
  border: 1px solid black;
  z-index: 8;
}

#spin{
  position: absolute;
  top:50%;
  left:51%;
  transform:translate(-51%,-50%);
  z-index:10;
  background-color: #e2e2e2;
  text-transform: uppercase;
  border:2px solid black;
  font-weight:bold;
  font-size:12px;
  color:black;
  width: 50px;
  height:50px;
  font-family: sans-serif;
  border-radius:50%;
  cursor: pointer;
  outline:none;
  letter-spacing: 1px;
}
 
.click-image-icon-size{
  width: 30%;
  height: 30%;
  z-index: 5;
}

.spinner-pointer-position{
  position: relative;
  right: 0px;
  top: 100px;
  z-index: 10;   
  
}

.z-index-on-top{
  z-index: 2;
}

.small-box
{
   width: 20px;
   height: 3px;
   background-color: #FF7f27;
}

.very-small-box
{
    width: 15px;
    height: 2px;
    background-color: #ff7f27;
}

.small-text-size
{
    font-size: 11px;
}


.very-small-text-size
{
    font-size: 10px;
}

.legend-background-color{
  background-color: #87FFFF;
}

.legend-position{
  position: absolute;
  left: 72%;
  top: 65%;
  width: 180px;
}

.legend-position-medium{
  position: absolute;
  left: 72%;
  top: 67%;
  width: 180px;
}

.legend-position-narrow{
  position: absolute;
  left: 72%;
  top: 67%;
  width: 180px;
}


.legend-card-caret
{
    font-size:1.5em;
}

