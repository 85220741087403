
.activity-board
{
    background-position: 0;
    background-size: cover;
    min-height: 100%;
    padding-right: 15px;
    overflow: auto;
}

.btn-primary-small
{
    background-color: #203864!important;
    border-color: #203864!important;
    border-width: 4px!important;
    color: white;
    font-size: 14px;
}

.btn-primary-very-small
{
    background-color: #203864!important;
    border-color: #203864!important;
    border-width: 4px!important;
    color: white;
    font-size: 10px;
}


.btn-bottom-of-page
{
    position: absolute;
    top: 90%;
    left: 90%;
    transform: translate(-90%, -90%);
}

.finish-title-position{
    position: absolute;
    left: 20%;
}

.table-background-lightgrey
{
    background-color: #DDDDDD;
    border-color: #DDDDDD;
    width: 60%;
}

.table-width-wide{
   width: 75%;
}

.text-color-lightgrey
{
    color: #DDDDDD;
}

.table-bottom-of-page
{
    position: absolute;
    top: 80%;
    left: 40%;
    transform: translate(-40%, -80%);
}
  
.activity_start_page_img {
    height: 11em;
    width: 17em;
}

.tutor_chalkboard_background {
    background-image: url('./gameBoardAssets/bg_chalkboard.png');
    background-size: cover;
} 

.build_home_background {
  background-image: url('./gameBoardAssets/bg_buildHome.png');
  background-size: cover;
} 


.soup_kitchen_background {
    background-image: url('./gameBoardAssets/bg_soup_kitchen.jpg');
    background-size: cover;
  } 


.choose-a-friend-img
{
    height: 9em;
    width:  3em;
}   


.friend-display-area2{
  height: 350px;
}

.friend-display-intro-text{
    width: 50%;
    position: absolute;
    left: 25%;
    top: 20%;
    text-align: center;
}

.activity-img-size{
    height: 50%;
    width: 50%;
}

.activity_thumbnail_img
{
    width: 10em;
}

.activity_thumbnail_img_small
{
    width: 8em;
}

