
.btn-input-category-selected
{
    border: solid!important;
    border-width: 4px!important;
    border-color: #A3B9E1!important;
    background-color: #203864!important;
    color: white!important
}

.home_card_detail_img
{
    height: 10em;
}

.home_card_thumbnail_img
{
    width: 10em;
    height: 5em;
}

.car_group_thumbnail_img{
    height: 5em;
    width: 11em;
}

.friend-display-area{
    height: 450px;
}

.ask-a-friend-img
{
    height: 11em;
    width:  3.5em;
}   

.friend-position-top-left{
    position: absolute;
    top: 5%;
    left: 10%;
}


.friend-position-top-right{
    position: absolute;
    top: 5%;
    left: 75%;
   
}

.friend-position-bottom-left{
    position: absolute;
    top: 55%;
    left: 10%;
}

.friend-position-bottom-right{
    position: absolute;
    top: 55%;
    left: 75%;
}

 /* (A) SPEECH BOX */
 .speech-adviceLeftTop {
    /* (A1) FONT */
    font-family: arial, sans-serif;
    font-size: 1.0em;
   
    /* (A2) COLORS */
    color: #fff;
    background: #262696;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 2%;
    left: 25%;
    padding: 15px;
    border-radius: 10px;
    max-width: 220px;
  }

  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-adviceLeftTop::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
  }

/* (C2) LEFT */
.left.speech-adviceLeftTop::after {
    /* (C2-1) LEFT TRIANGLE */
    border-right-color: #262696;
    border-left: 0;
   
    /* (C2-2) POSITION AT LEFT */
    left: -20px; top: 25%;
    margin-top: -20px;
  }

  
 /* (A) SPEECH BOX */
 .speech-adviceRightTop {
    /* (A1) FONT */
    font-family: arial, sans-serif;
    font-size: 1.0em;
   
    /* (A2) COLORS */
    color: #fff;
    background: #262696;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 2%;
    left: 28%;
    padding: 15px;
    border-radius: 10px;
    max-width: 220px;
  }

  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-adviceRightTop::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
  }

    /* (C3) RIGHT */
.right.speech-adviceRightTop::after {
    /* (C3-1) RIGHT TRIANGLE */
    border-left-color: #262696;
    border-right: 0;
   
    /* (C3-2) POSITION AT RIGHT */
    right: -20px; top: 30%;
    margin-top: -20px;
  }
 
  
 /* (A) SPEECH BOX */
 .speech-adviceLeftBottom {
    /* (A1) FONT */
    font-family: arial, sans-serif;
    font-size: 1.0em;
   
    /* (A2) COLORS */
    color: #fff;
    background: #262696;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 53%;
    left: 25%;
    padding: 15px;
    border-radius: 10px;
    max-width: 220px;
  }

  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-adviceLeftBottom::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
  }

/* (C2) LEFT */
.left.speech-adviceLeftBottom::after {
    /* (C2-1) LEFT TRIANGLE */
    border-right-color: #262696;
    border-left: 0;
   
    /* (C2-2) POSITION AT LEFT */
    left: -20px; top: 25%;
    margin-top: -20px;
  }

  
 /* (A) SPEECH BOX */
 .speech-adviceRightBottom {
    /* (A1) FONT */
    font-family: arial, sans-serif;
    font-size: 1.0em;
   
    /* (A2) COLORS */
    color: #fff;
    background: #262696;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 53%;
    left: 28%;
    padding: 15px;
    border-radius: 10px;
    max-width: 220px;
  }

  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-adviceRightBottom::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
  }

    /* (C3) RIGHT */
.right.speech-adviceRightBottom::after {
    /* (C3-1) RIGHT TRIANGLE */
    border-left-color: #262696;
    border-right: 0;
   
    /* (C3-2) POSITION AT RIGHT */
    right: -20px; top: 30%;
    margin-top: -20px;
  }

  .alert {
    padding: 20px;
    background-color: green;
    color: white;
  }

  .display-btn-right {
    position: absolute;
    right: 10px;
  } 

 
