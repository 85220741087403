
.root {
    margin: auto;
    width: 50%;
    border: 5px solid #7f92b6;
    padding-left: 10px;
    padding-bottom: 10px;
    min-height: 250px;
    min-width: 350px;
    background-color: white;
  }

.root2 {
  margin: auto;
  width: 100%;
  border: 5px solid #7f92b6;
  padding-left: 10px;
  padding-bottom: 10px;
  min-height: 250px;
  min-width: 350px;
  background-color: white;
}
  
  .cell {
    height: calc(5.5vh);
    width: calc(5.5vh);
    cursor: pointer;
    background-color: #dde1e9 !important;
  }

  .path {
    height: calc(6vh);
    width: calc(6vh);
    cursor: pointer;
    background-color: #C28820 !important;
  }
  
  .snow {
    height: calc(6vh);
    width: calc(6vh);
    cursor: pointer;
    background: url(./gameBoardAssets/snow.png);
  }
  
  .river {
    height: calc(6vh);
    width: calc(6vh);
    cursor: pointer;
    background: url(./gameBoardAssets/river.png);
  }

  .user-form {
    float: right;
  }
  
  .plain {
    display: none;
  }
 
  .car {
    display: block;
    height: 90%;
    width: 90%;
    background: url(./gameBoardAssets/carLeft.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .house {
    display: block;
    height: 100%;
    width: 80%;
    margin: auto;
    background: url(./gameBoardAssets/house.png);
    background-size: 100% 100%;
  }

  .beach {
    display: block;
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: #E3B157;
    background-size: 100% 100%;
  }
  
  .dogWalker {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/dogWalker.png);
    background-size: 100% 100%;
    margin: auto;
  }

  .raft {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/raft.png);
    background-size: 100% 100%;
    margin: auto;
  }
   
  .fireHydrant {
    display: block;
    height: 100%;
    width: 70%;
    margin: auto;
    background: url(./gameBoardAssets/fireHydrant.png);
    background-size: 100% 100%;
  }
   
  .greenSquare {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/greenSquare.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .blackSquare {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/blackSquare.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .whiteSquare {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/whiteSquare.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .rocks {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/rocks.png);
    background-size: 100% 100%;
    margin: auto;
  }

  .largeRock {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/largeRock.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .skier {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/skiier.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .trees {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/trees.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
  .pineTree {
    display: block;
    height: 80%;
    width: 80%;
    background: url(./gameBoardAssets/pineTree.png);
    background-size: 100% 100%;
    margin: auto;
  }
    
  .skiLift {
    display: block;
    height: 100%;
    width: 100%;
    background: url(./gameBoardAssets/skiLift.png);
    background-size: 100% 100%;
    margin: auto;
  }
  
.three_column_display{
  column-count: 3;
  column-gap: 10px;
}

.font_color_white{
  color: white;
}


  