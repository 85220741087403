
.worker-img-wide
{
    height:11em;
    width: 6em;
}

.worker-img-narrow
{
    height:8em;
    width: 2em;
}

.w-90
{
    width: 90%!important;
}

.career_card_caret
{
    font-size:1.5em;
}
 
.drop-shadow
{
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.job_profile_thumbnail_img
{
    height: 8em;
    width: 8em;
    }
    
.furniture_thumbnail_img
{
    height: 8em;
    width: 12em;
    }

.very_very_small_img
    {
        width: 50px;
        height: 50px;
    }
    
.gameModalHdrFtrBackground {
        background-color: #B1D7F1;
     }

.question-color {
    color: #5283D2
}

.larger-text-size {
    font-size: 1.2rem;
}

.skills-interest-picture{
    width: 30%;
    height: 30%;
    position: absolute;
    top: 65%;
    left: 30%;
}

.preset-game-button {
    width: 90%;
    background-color: #478CD6;
    border-radius: 20px;
    border-width: 2px;
    border-color: #002E8A;
 /*   font-family: "Comic Sans MS", cursive, sans-serif; */
 /*   font-weight: bolder; */
    vertical-align: middle;
    color:  white;
    height: 30px;
}

