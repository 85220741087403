
.drag_n_drop_regular_column
{
   margin: 1rem;
   border: 1px solid black;
   border-radius: 5px;
   width:100%;
   display:flex;
   flex-direction: column;
   white-space: pre-wrap;
   background-color: white
}

.drag_n_drop_coats_column
{
   margin: 1rem;
   border: 1px solid black;
   border-radius: 5px;
   width: 100%;
   min-height: 80%;
   display:flex;
   flex-direction: column;
   white-space: pre-wrap;
   background-color: #00339A
}

.drag_n_drop_zoo_column
{
   margin: 1rem;
   border: 1px solid black;
   border-radius: 5px;
   width: 70%;
   min-height: 80%;
   display:flex;
   flex-direction: column;
   white-space: pre-wrap;
   background-color: #00339A
}

.text-green
{
    color: green;
}

.drag_n_drop_grocery_cart
{
    background-image: url('./gameBoardAssets/bg_shoppingCart.png');
    background-size: 100% 100%;
}

.drag_n_drop_shelves_column
{
    background-image: url('./gameBoardAssets/shelves.png');
    background-size: 100% 100%;
}

.drag_n_drop_blue_column
{
    background-image: url('./gameBoardAssets/dragNDropColumn.png');
    background-size: 100% 100%;
}

.drag_n_drop_basic_column
{
   border: 1px solid black;
   border-radius: 5px;
   width: 70%;
   border-width: 2px;
   white-space: pre-wrap;
   min-height: 450px;
}

.drag_n_drop_warehouse_background {
    background-image: url('./gameBoardAssets/bg_warehouse.png');
    background-size: 100%;
} 

.drag_n_drop_zoo_background {
    background-image: url('./gameBoardAssets/bg_zoo.png');
    background-size: 100%;
} 

.drag_n_drop_grocery_background {
    background-image: url('./gameBoardAssets/bg_grocery_store.png');
    background-size: 100%;
} 

.drag_n_drop_activity_topbottom {
    background-image: url('./gameBoardAssets/bg_warehouse_color.png');
} 

.dnd_picture_size {
    height: 30em;
    width: 100%;
}


.drag_n_drop_thumbnail_img
{
    height: 65px;
    width: 40px;
    object-fit: cover;
    margin-bottom: 5px;
    }

 .food_drive_thumbnail_img
{
    height: 67px;
    width: 45px;
    object-fit: cover;
    margin-bottom: 5px;
    }

    
 .food_drive_thumbnail_img_medium
 {
     height: 50px;
     width: 35px;
     object-fit: cover;
     margin-bottom: 5px;
     }

 .food_drive_thumbnail_img_small
{
    height: 40px;
    width: 25px;
    object-fit: cover;
    margin-bottom: 5px;
    }


.drag_n_drop_item_cannedFood {
    padding:4px;
    width: 0px;
    margin-bottom:3px;
    border-radius:1px;
    white-space: pre-wrap;
}   

 .drag_n_drop_coat_img
{
    height: 30%;
    width: 80%;
    object-fit: cover;
    }

.drag_n_drop_zoo_animal_img
{
    height: 40px;
    width: 140px;
    object-fit: fill;
    align-items: center;
        }


.drag_n_drop_zoo_animal_img_small
{
    height: 35px;
    width: 90px;
    object-fit: fill;
    align-items: center;
    padding: 0
        }
    

.multiple-columns{
    column-count: 3;
}

.single-column{
    column-count: 1;
}

.friend-position-img{
    height: 14em;
    width: 4.25em;
    position: absolute;
    top: 93%;
    left: 23%;
    transform: translate(-23%, -93%);
}
 /* (A) SPEECH BOX */
 .speech-activity {
    /* (A1) FONT */
    font-family: arial, sans-serif;
    font-size: .9em;
   
    /* (A2) COLORS */
    color: #fff;
    background: #001C54;
   
    /* (A3) DIMENSIONS + POSITION */
    
    position: absolute;
    top: 75%;
    left: 8%;
    transform: translate(-8%, -75%);
  
     padding: 15px;
    border-radius: 10px;
    max-width: 170px;
  }

  /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech-activity::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
  }

    /* (C3) RIGHT */
.right.speech-activity::after {
    /* (C3-1) RIGHT TRIANGLE */
    border-left-color: #001C54;
    border-right: 0;
   
    /* (C3-2) POSITION AT RIGHT */
    right: -20px; top: 30%;
    margin-top: -20px;
  }

  .no-column-gutters{
    width: 100%;
    max-width: 100%!important;

  }
