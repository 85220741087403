.good-looking-button {
    width: 80%;
    background-color: #478CD6;
    border-radius: 20px;
    border-width: 2px;
    border-color: black;
    font-family: "Comic Sans MS", cursive, sans-serif;
    font-weight: bolder;
    vertical-align: middle;
    color:  #E2F9FD;
    height: 50px;
}

.underline-this-text {
    text-decoration: underline;
}

.small_size_img {
    width: 90px;
    height: 70px;
}

.modal-80{
    width: 80%;
    max-width: 80%!important;
}

