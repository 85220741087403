
.small-facedown-game-card
{
    height: 60px!important;
    width: 40px!important;
    border-radius: 2px;
    border-width: 2px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    position: absolute;
}

.card-number-position
{
   position: absolute;
   left: 17px;
   top: 35px;
}

.small-box
{
   width: 20px;
   height: 3px;
   background-color: #FF7f27;
}

.medium-text-size
{
    font-size: 12px;
}

.small-text-size
{
    font-size: 10px;
}

.legend-position{
  background-color: #87FFFF;
  position: absolute;
  left: 63%;
  top: 67%;
}

.legend-card-caret
{
    font-size:1.5em;
}

.display-event-position
{
    position: relative;
    top: 1450%;
    left: 20%;
}


.game-card-blue
{
    background-color: #7490C3;
    border-color: #203864!important;
}


.game-card-green
{
    background-color: #2FFF97;
    border-color: #00BC5E!important;
}

.dialog-info-position{
    position: absolute;
    left: 60%;
}

.faceup-game-card
{
    height: 280px!important;
    width: 200px!important;
    border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    position: absolute;
    top: 15%;
  }

.table-background-lightblue
{
    background-color: #C6F1FF;
}

.table-background-lightgreen
{
    background-color: #C9FBB7;
}

.table#responseTable {
    table-layout: fixed ;
    width: 100% ;
 }
  table#responseTable th {
    vertical-align: top;
    width: 12% ;
   
  }
  table#responseTable td {
    width: 20% ;
   
  }

  
.flip-card {
    background-color: transparent;
    width: 100px;
    height: 150px;
    perspective: 1000px;
    position: absolute;
    left: 60%;
    top: 25%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    height: 100%;
   /* position: absolute;
    top: 173px;*/
  }
  
  .flip-card-back {
     border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    cursor: pointer;
    background-color: transparent;
    transform: rotateY(180deg);
  }
  
  
.facedown-game-card
{
    border-radius: 4px;
    border-width: 3px!important;
    border-style: solid;
    box-shadow: 2px 2px rgba(0,0,0,0.3);
    cursor: pointer;
}
  .very-large-size-font
{
    font-size: 2em;
}


.medium-size-font
{
    font-size: 1em;
}

.envelope-number-position {
    position: absolute;
    top: 40%;
}




