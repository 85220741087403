
container {
  position:relative;
  height:400px;
  }
  
  .contact {
      position: absolute;
 /*     left: 50%;  replaced by component */
 /*     top: 50%;   replaced by component */
      margin-left: -125px;
      margin-top: -125px;
      height: 120px;
      width: 61px;
 /*     background: rgba(0, 0, 0, 0.1);  replaced by component */
      border-radius: 2px;
      -ms-border-radius: 2px;
      -moz-border-radius: 2px;
      -o-border-radius: 2px;
      -webkit-border-radius: 2px;
  }
  .contact .envelope {
      position: absolute;
      height: 93px;
      width: 45px;
      left: 150%;
      margin-left: -83px;
      top: 63%;
      margin-top: -65px;
      background: #F9F9F9;
      transition: margin-top 300ms;
      -ms-transition: margin-top 300ms;
      -moz-transition: margin-top 300ms;
      -o-transition: margin-top 300ms;
      -webkit-transition: margin-top 300ms;
  }
  .contact:hover .envelope {
      transition-delay: 150ms;
      -ms-transition-delay: 150ms;
      -moz-transition-delay: 150ms;
      -o-transition-delay: 150ms;
      margin-top: -20px;
  }
  .contact .envelope .top {
      position: absolute;
      top: -5px;
      left: 0px;
      width: 100%;
      height: 45px;
      z-index: 30;
      overflow: hidden;
      transform-origin: top;
      -ms-transform-origin: top;
      -moz-transform-origin: top;
      -o-transform-origin: top;
      -webkit-transform-origin: top;
      transition: transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms, top 300ms 0ms;
      -ms-transition: -ms-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms, top 300ms 0ms;
      -moz-transition: -moz-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms, top 300ms 0ms;
      -o-transition: -o-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms, top 300ms 0ms;
      -webkit-transition: -webkit-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms, top 300ms 0ms;
  }
  .contact:hover .envelope .top {
      transition: transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
      -ms-transition: -ms-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
      -moz-transition: -moz-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
      -o-transition: -o-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
      -webkit-transition: -webkit-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
      height: 10px;
      top: -60px;
      transform: rotateX(180deg);
      -ms-transform: rotateX(180deg);
      -moz-transform: rotateX(180deg);
      -o-transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
  }
  .contact .envelope .outer {
      position: absolute;
      bottom: 0px;
      left: 0px;
     border-left: 21px solid transparent;
      border-right: 26px solid transparent;
      border-top: 46px solid #969696;
  }
  .contact .envelope .outer .inner {
      position: absolute;
      left: -81px;
      top: -73px;
      border-left: 21px solid transparent;
      border-right: 26px solid transparent;
      border-top: 40px solid pink;
  }
  .contact .envelope .bottom {
      position: absolute;
      z-index: 20;
      bottom: 0px;
      left: 2px;
      border-left:  21px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 49px solid #969696;
  }
  .contact .envelope .left {
      position: absolute;
      z-index: 20; 
      top: 0px;
      left: 0px;
      border-left: 24px solid #969696;
      border-top: 42px solid transparent;
      border-bottom: 54px solid transparent;
  }
  .contact .envelope .right {
      position: absolute;
      z-index: 20;
      top: 0px;
      right: 0px;
      border-right: 24px solid #969696;
      border-top: 42px solid transparent;
      border-bottom: 54px solid transparent;
  }
  .contact .envelope .cover {
      position: absolute;
      z-index: 15;
      bottom: 0px;
      left: 0px;
      height: 55%;
      width: 100%;
      background: #7490C3;
  }
  .contact .envelope .paper {
      position: absolute;
      height: 75px;
      padding-top: 10px;
      width: 100%;
      border-radius: 4px;
      border-width: 3px!important;
      border-style: solid;
      background-color: #7490C3;
      border-color: #203864!important;
      z-index: 10;
      transition: margin-top 300ms 0ms;
      -ms-transition: margin-top 300ms 0ms;
      -moz-transition: margin-top 300ms 0ms;
      -o-transition: margin-top 300ms 0ms;
      -webkit-transition: margin-top 300ms 0ms;
  }
  .contact:hover .envelope .paper {
      margin-top: -60px;
      transition: margin-top 300ms 150ms;
      -ms-transition: margin-top 300ms 150ms;
      -moz-transition: margin-top 300ms 150ms;
      -o-transition: margin-top 300ms 150ms;
      -webkit-transition: margin-top 300ms 150ms;
  }
  .contact .envelope .paper a {
      position: relative;
      display: block;
      font-size: 14px;
      margin: 5px;
      margin-bottom: 0px;
      text-align: center;
      color: #333;
      text-decoration: none;
  }
  .contact .envelope .paper a.call .i {
      position: absolute;
      top: 2px;
      left: 20px;
      display: inline-block;
      width: 3px;
      height: 5px;
      border-width: 5px 0 5px 2px;
      border-style: solid;
      border-color: #555;
      background: transparent;
      transform: rotate(-30deg);
      -ms-transform: rotate(-30deg);
      -moz-transform: rotate(-30deg);
      -o-transform: rotate(-30deg);
      -webkit-transform: rotate(-30deg);
      border-top-left-radius: 3px 5px;
      border-bottom-left-radius: 3px 5px;
      -moz-border-radius-topleft: 3px 5px;
      -moz-border-radius-bottomleft: 3px 5px;
      -webkit-border-top-left-radius: 3px 5px;
      -webkit-border-bottom-left-radius: 3px 5px;
      transition: border-color 300ms;
      -ms-transition: border-color 300ms;
      -moz-transition: border-color 300ms;
      -o-transition: border-color 300ms;
      -webkit-transition: border-color 300ms;
  }
  .contact .envelope .paper a {
      color: #333;
      transition: color 200ms;
      -ms-transition: color 200ms;
      -moz-transition: color 200ms;
      -o-transition: color 200ms;
      -webkit-transition: color 200ms;
  }
  .contact .envelope .paper a:hover {
      color: #2e3696;
  }
  .contact .envelope .paper a.call:hover .i {
      border-color: #2e3696;
  }
  .contact .envelope .paper a.mail .i {
      position: absolute;
      top: 0px;
      left: 17px;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
  }

  
  .contact2 {
    position: absolute;
/*     left: 50%;  replaced by component */
/*     top: 50%;   replaced by component */
    margin-left: -125px;
    margin-top: -125px;
    height: 140px;
    width: 61px;
/*     background: rgba(0, 0, 0, 0.1);  replaced by component */
    border-radius: 2px;
    -ms-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    -webkit-border-radius: 2px;
}
  
.contact2 .envelope2 {
    position: absolute;
    height: 93px;
    width: 45px;
    left: 150%;
    margin-left: -83px;
    top: 63%;
    margin-top: -45px;
    background: #F9F9F9;
    transition: margin-top 300ms;
    -ms-transition: margin-top 300ms;
    -moz-transition: margin-top 300ms;
    -o-transition: margin-top 300ms;
    -webkit-transition: margin-top 300ms;
}

.contact2 .envelope2 .top {
    top: -40%;
    position: absolute;
        width: 0; 
        height: 0; 
        border-left: 23px solid transparent;
        border-right: 23px solid transparent;
        border-bottom: 38px solid white;
}
.contact2 .envelope2 .bottom {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    left: 2px;
    border-left:  21px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 49px solid #969696;
}
.contact2 .envelope2 .left {
    position: absolute;
    z-index: 20; 
    top: 0px;
    left: 0px;
    border-left: 22px solid #969696;
    border-top: 42px solid transparent;
    border-bottom: 54px solid transparent;
}
.contact2 .envelope2 .right {
    position: absolute;
    z-index: 20;
    top: 0px;
    right: 0px;
    border-right: 22px solid #969696;
    border-top: 42px solid transparent;
    border-bottom: 54px solid transparent;
}

.envelope-legend-position {
    position: absolute;
    left: 60%;
    top: 67%;
    width: 180px;
}

.medium-text-size {
    font-size: 14px;
}
  