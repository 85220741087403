.badgeContainer{
    width:600px;
    height: 450px;
    background-color: blue;
  }

@keyframes rotatePicture {
    100% {transform: rotate(359deg)};
 }
 
 .spinPictureAround{
   animation: rotatePicture 2s 2 linear;
   animation-delay: .5s;
 }
 
 .badgeImage{
    height: 190px;
    width: 190px;  
    position: relative;
    top: 40%;
    left: 5%;   /* was 5% */
  }
  
 .badgeImage-narrow{
  height: 190px;
  width: 190px;  
  position: relative;
  top: 40%;
  left: -10%;   
}
 
 .badgeImageSmall{
    height: 40px;
    width: 40px;
    position: absolute;
    left: 45%;
    top: 44%;
    z-index: 2;
 }

 .badgeDisabled{
    opacity: 0.5;
 }
 
.badge-small-text
{
    font-size: 14px;
}
  
 .shrinkPictureSize{
    animation: shrinkPicture 2s 1 linear;
    position: relative;
    left: 30px; /* was 30px */
 }
  
 @keyframes shrinkPicture {
     100% {transform: scale(.25, .25)}
 }
  
 .badgeModal{
    width: 300px!important;
}

.badgeModalNarrow{
    width: 230px!important;
}

@keyframes slidePicture{
  0% {left: 45%;top: 44%;}
  100% {left: 45%; top: 95%}
}

.movePicture{
    animation: slidePicture 1s 1 linear;
}
