
.train-video-rectangle{
    height: 500px;
 }

  .header-sticky {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color:  #B6D0EB;
  }

  .modal-no-margin{
    margin-top: 0;
  }
  
.thumbs-img-feedback
{
    height: 30%;
    width: 30%;
}   

