.table-light-grey-background
{
    background-color: rgba(198,212,237, 0.95);
 }

 .train-extra-credit-position
 {
    position: absolute;
    left: 35%;
 }
 
.trainLoadingMidSize {
    height: 30%!important;
    width: 30%!important;
  }

.modal-95{
   width: 95%;
   max-width: 95%!important;
}

.test-train-right {
   position: absolute;
   top: 78%;
   right: 0%;
   z-index: 5;
}

.wide-screen-train-size{
   height: 3em;
   width:  3.2em;
}

.medium-screen-train-size{
   height: 3em;
   width:  2.6em;
}

.narrow-screen-train-size{
   height: 3em;
   width:  1.9em;
}

.move-test-train-wide{
   animation: moveLeftWide 2s 1 linear forwards;
   animation-delay: .5s;
}

@keyframes moveLeftWide {
   0%   {right: 0px }
  100%  {right: 90px;} 
}

.move-test-train-medium{
   animation: moveLeftMedium 2s 1 linear forwards;
   animation-delay: .5s;
}

@keyframes moveLeftMedium {
   0%   {right: 0px }
  100%  {right: 60px;} 
}

.move-test-train-narrow{
   animation: moveLeftNarrow 2s 1 linear forwards;
   animation-delay: .5s;
}

@keyframes moveLeftNarrow {
   0%   {right: 0px }
  100%  {right: 40px;} 
}

.train-track-position-size{
   position: absolute;
   top: 87%;
   left: 0%;
   z-index: 4;
   height: 8%;
   width: 100%;
}

.button-float-right{
    float: right;
}

.train-station-background{
   position: absolute;
   top: 80%;
   left: 0%;
   z-index: 3;
   height: 20%;
   width: 100%;
   border-bottom-right-radius: 75px;
   border-bottom-left-radius: 75px;
}

.super-star-info{
   height: 70%;
   width:  70%;
}

.super-star-info2{
   height: 70%;
   width:  70%;
}

.star-spin-around{
   animation: backAndForth 1s 5;
   animation-delay: .5s;
}

@keyframes backAndForth {
   100% {transform: rotate(359deg)};
}

.checkbox-container {
   display: flex;
   align-items: center; /* Align items vertically in the center */
 }
 
 .checkbox-label {
   width: 600px;
   display: inline-block; 
   vertical-align: top;
  
 }

 .radio-format {
   vertical-align: middle;
 }
 
 .checkbox-text {
   margin-left: 8px;
    
 }
 
 
.good-looking-button-extraCredit {
   width: 40%;
   background-color: #478CD6;
   border-radius: 20px;
   border-width: 2px;
   border-color: black;
   font-family: "Comic Sans MS", cursive, sans-serif;
   font-weight: bolder;
   vertical-align: middle;
   color:  #E2F9FD;
   height: 50px;
}

.mainline {
   white-space: nowrap; }

.joke-monster {
   width: 20%;
   height: 20%;
   position: absolute;
   top: 95%;
   left: 55%;
   transform: translate(-55%, -95%);
}

 /* (A) SPEECH BOX */
 .monsterSpeech {
   /* (A1) FONT */
   font-family: arial, sans-serif;
     
   /* (A2) COLORS */
   color: #fff;
   background: #262696;
  
   /* (A3) DIMENSIONS + POSITION */
   
   position: absolute;
   top: 90%;
   left: 17%;
   transform: translate(-17%, -90%);
 
   padding: 10px;
   border-radius: 10px;
   width: 35%;
 }


 /* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.monsterSpeech::after {
 /* (B1-1) ATTACH TRANSPARENT BORDERS */
 content: "";
 border: 20px solid transparent;

 /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
 position: absolute;
}

/* (B2) BOTTOM "CALLOUT TAIL" */
.bottom.monsterSpeech::after {
 /* (B2-1) DOWN TRIANGLE */
 border-top-color: #262696;
 border-bottom: 0;

 /* (B2-2) POSITION AT BOTTOM */
 bottom: -20px; left: 70%;
 margin-left: -20px;
}

/* (C3) RIGHT */
.right.monsterSpeech::after {
 /* (C3-1) RIGHT TRIANGLE */
 border-left-color: #262696;
 border-right: 0;

 /* (C3-2) POSITION AT RIGHT */
 right: -15px; 
 top: 70%;
 margin-top: -28px;
}

 